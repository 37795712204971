const firebaseConfig = {
    apiKey: "AIzaSyCF-aQtFPxzVeCO3Y50OL2ZG72EDNIPFPA",
    authDomain: "eduapp-13ca8.firebaseapp.com",
    databaseURL: "https://eduapp-13ca8.firebaseio.com",
    projectId: "eduapp-13ca8",
    storageBucket: "eduapp-13ca8.appspot.com",
    messagingSenderId: "820912541023",
    appId: "1:820912541023:web:a85747ed8cd3cdb2a97aea"
  };

  export default firebaseConfig